import React from 'react';
import { ICorpHeroWrapperContainerProps } from './types';
import { CurveMask } from '../CurveMask';

const backgroundWrapperClasses = [
  'bg-center',
  'bg-cover',
  'w-full',
  'overflow-hidden',
  'items-center',
  'flex',
  'flex-col',
  'relative',
  'md:justify-start',
  'md:pt-0',
  'md:justify-center',
  'h-heroCarouselInnerTallMob',
  'md:h-heroCarouselInnerTallBannerHeight',
].join(' ');

export const HeroWrapperContainer = ({ children }: ICorpHeroWrapperContainerProps) => (
  <section className="relative my-0 p-0 min-w-full z-40 overflow-hidden" data-print="hide">
    <div className="curve-mask-top relative pb-[40px] bg-[rgb(1,14,84)] overflow-x-hidden -z-[1]">
      <div className="curve-mask-bottom overflow-y-hidden">
        <div className="w-full overflow-x-hidden">
          <div className={backgroundWrapperClasses}>{children}</div>
        </div>
      </div>
    </div>
    <CurveMask />
  </section>
);
