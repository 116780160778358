import React from 'react';
import FadeInSection from '../FadeInSection';
import { HeroWrapperContainer } from './CorpHeroWrapperContainer';
import { ICorpHeroProps } from './types';
import { CorporateButton } from './../CorporateButton';
import { Text, Title } from './../Typography';
import { useRouter } from 'next/router';
import { CorpHeroContentWrapper } from './CorpHeroContentWrapper';
import { CorpHeroBackground } from './CorpHeroBackground';

export const CorpHero = ({
  bannerImage: image,
  title,
  link,
  description,
  backgroundGradient,
  superText,
  imageAlt,
  mobileImage,
}: ICorpHeroProps) => {
  const { locale: market } = useRouter();
  const url = link?.internalLink?.slug
    ? `/${market === 'global' ? '' : `${market}/`}${link?.internalLink?.slug}`
    : link?.url;
  return (
    <HeroWrapperContainer>
      <CorpHeroBackground
        backgroundGradient={backgroundGradient}
        image={image}
        imageAlt={imageAlt}
        mobileImage={mobileImage}
      />

      <CorpHeroContentWrapper>
        {superText && (
          <FadeInSection delay="300ms">
            <Text className="text-corporate-blue opacity-70 pt-0.5" tag="p" type="lead">
              {superText}
            </Text>
          </FadeInSection>
        )}
        {title && (
          <FadeInSection delay="600ms">
            <Title className="text-corporate-blue mb-5" tag="h1" type="lg">
              {title}
            </Title>
          </FadeInSection>
        )}
        {description && (
          <FadeInSection delay="900ms">
            <Text className="text-corporate-blue" data-test={[`text`]} tag="p" type="lg">
              {description}
            </Text>
          </FadeInSection>
        )}
        {url && (
          <FadeInSection delay="1200ms">
            <div className=" relative mt-7.5 h-[50px] w-full md:w-[250px]">
              <a
                className="text-[0.75rem] leading-[1rem] lg:text-[0.875rem] lg:leading-[1.25rem]"
                href={url}
              >
                <span className="sr-only">{link?.displayName}</span>
                <CorporateButton
                  type="primary"
                  className="mx-auto md:mx-0"
                  label={link?.displayName}
                  buttonType="button"
                />
              </a>
            </div>
          </FadeInSection>
        )}
      </CorpHeroContentWrapper>
    </HeroWrapperContainer>
  );
};
