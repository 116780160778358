import { Grid, GridRow, GridItem } from 'components/Grid';
import { FC, PropsWithChildren } from 'react';

export const CorpHeroContentWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Grid>
      <GridRow columns={16}>
        <GridItem colStart={2} colSpan={8}>
          <div className="text-left">
            <div className="sm:text-center md:text-left pt-12 md:pt-0">{children}</div>
          </div>
        </GridItem>
      </GridRow>
    </Grid>
  );
};
