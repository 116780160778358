import { FC } from 'react';
import { getPictureSources } from '../../lib/utils/helpers';
import { Picture } from 'react-responsive-picture';

interface CorpHeroBackground {
  backgroundGradient: boolean;
  mobileImage?: Record<string, string>;
  image?: Record<string, string>;
  imageAlt?: string;
}

export const CorpHeroBackground: FC<CorpHeroBackground> = ({
  backgroundGradient,
  mobileImage,
  image,
  imageAlt,
}) => {
  const pictureClassName = [
    'h-580',
    '-z-10',
    'w-full',
    'h-full',
    'absolute',
    'top-0',
    'md:top-0',
    'object-cover',
    'overflow-hidden',
  ].join(' ');
  return (
    <>
      <div className={`block md:hidden w-full h-full hero-banner-image Tall absolute top-0 left-0`}>
        {backgroundGradient && (
          <div className="w-full h-full bg-gradient-to-b from-[rgba(255,255,255,1)] from-20% to-[rgba(255, 255, 255, 0)] to-90%" />
        )}
        <Picture
          className={pictureClassName}
          sources={mobileImage?.mobile ? getPictureSources(mobileImage) : getPictureSources(image)}
          alt={imageAlt}
        />
      </div>
      <div className="hidden md:block w-full h-full hero-desktop-image absolute top-0 left-0">
        {backgroundGradient && (
          <div className="w-full h-full bg-gradient-to-r from-[rgba(255,255,255,1)] from-15% to-[rgba(255, 255, 255, 0)] to-75%" />
        )}
        <Picture className={pictureClassName} sources={getPictureSources(image)} alt={imageAlt} />
      </div>
    </>
  );
};
